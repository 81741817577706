import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero';
import { H2, P, ColoredText } from '../components/typography';
import Container from '../components/container';
import Section from '../components/section';
import { fluidImageType, fluidImage, nodesOf } from '../helper/prop-types';
import ImageText from '../components/image-text';
import { getImageFromNode } from '../helper/utils';

const AboutPage = ({ data }) => (
    <Layout
        title="Alles rund um Die Original Lechtaler"
        description="Hier erfahren Sie Wissenswertes über Die Original Lechtaler. Von der Herkunft bis zur Qualitätssicherung."
        conclusionBoxes={['products', 'recipes']}
    >
        <Hero title="Über uns" image={data.file.childImageSharp.fluid} />
        <Section>
            <Container maxWidth="small">
                <H2 textCentered largeFont>
                    Ein besonderes Genusserlebnis
                </H2>
                <P textCentered intro>
                    Die zur Mühlen Gruppe ist eines der führenden Unternehmen der Fleisch- und
                    Wurstbranche in Europa. Zu ihr gehören unter anderem die beliebten Wurst-Marken
                    Böklunder, Gutfried, hareico, Lutz, Könecke und Weimarer Thüringen. <br />
                    <ColoredText>
                        Nun haben wir unseren Markenstamm um ein besonderes Genusserlebnis erweitert
                        –<br />
                    </ColoredText>
                    <ColoredText primaryColor>Die Original Lechtaler.</ColoredText>
                </P>
            </Container>
        </Section>
        <Section>
            <Container maxWidth="inbetweenSmallMedium">
                <ImageText
                    alt="Fleischwerk am Standort Augsburg"
                    image={
                        getImageFromNode(data.aboutImages, 'content/about/company-building.jpg')
                            .node.childImageSharp.fluid
                    }
                >
                    <P gap="none">
                        Die{' '}
                        <ColoredText primaryColor>
                            Landsberger Wurstspezialitäten GmbH & Co. KG
                        </ColoredText>{' '}
                        stellt das komplette Sortiment der Marke Die Original Lechtaler her. Der
                        Produktionsstandort befindet sich im oberbayerischen{' '}
                        <ColoredText primaryColor>Landsberg am Lech</ColoredText> und liegt ziemlich
                        mittig des gesamten Flusslaufs. Der Lech entspringt in Vorarlberg und fließt
                        durch Tirol und Süddeutschland bis er in die Donau mündet. Die Nähe zum Lech
                        und die geografische Lage des Lechfeld bieten einen außergewöhnlichen
                        Standort. Im Werk werden Kochschinken, Brühwurst und bayerische
                        Spezialitäten produziert. Das Sortiment wurde nun um 15 Artikel unter der
                        Marke Die Original Lechtaler ausgebaut. Unter Die Original Lechtaler werden
                        ausschließlich{' '}
                        <ColoredText primaryColor>süddeutsche Wurstspezialitäten</ColoredText> mit
                        traditionellen Wurzeln und{' '}
                        <ColoredText primaryColor>einzigartigem Geschmack</ColoredText> hergestellt.
                        Die Qualitätssicherung umfasst den gesamten Produktionsprozess von den
                        ausgewählten Zutaten bis hin zur Anlieferung beim Kunden. Dabei gehen wir
                        mit der Zutat Fleisch verantwortungsbewusst um und achten besonders auf
                        dessen <ColoredText primaryColor>Qualität</ColoredText>. Auch der wachsenden
                        Bedeutung des <ColoredText primaryColor>Umweltschutzes</ColoredText> wird
                        als freiwillige Verpflichtung Rechnung getragen.
                    </P>
                </ImageText>
                <ImageText
                    alt="Händewaschen mit Seife"
                    rtl
                    image={
                        getImageFromNode(data.aboutImages, 'content/about/food-hygiene.jpg').node
                            .childImageSharp.fluid
                    }
                >
                    <div>
                        <P gap="none">
                            Alle unsere Werke arbeiten auf dem{' '}
                            <ColoredText primaryColor>höchsten Qualitätsstandard</ColoredText>. Dass
                            unsere Produkte mikrobiologisch, physikalisch und chemisch permanent
                            überwacht werden, ist für uns selbstverständlich. Zusätzlich
                            gewährleisten wir eine Überwachung und Kontrolle aller Produkte in
                            externen Laboratorien sowie die Teilnahme am QS-System sowie weiterer
                            externer Kontrollen. Natürlich gewährleisten wir höchste
                            Lebensmittelsicherheit im Rahmen unseres gesamtheitlichen{' '}
                            <ColoredText primaryColor>HACCP-Konzeptes</ColoredText>. Unser
                            Unternehmen ist nach den höchsten nationalen und internationalen
                            Standards zertifiziert:
                        </P>
                        <P gap="none" as="ul">
                            <li>QS Qualität und Sicherheit</li>
                            <li>International Food Standard (IFS)</li>
                            <li>GQB – Geprüfte Qualität Bayern</li>
                            <li>Regionalfenster</li>
                            <li>VLOG – Lebensmittel ohne Gentechnik</li>
                        </P>
                    </div>
                </ImageText>
            </Container>
        </Section>
    </Layout>
);

AboutPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
        aboutImages: nodesOf(
            PropTypes.shape({
                relativePath: PropTypes.string.isRequired,
                childImageSharp: fluidImage.childImageSharp,
            })
        ),
    }).isRequired,
};

export default AboutPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-biergarden.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        aboutImages: allFile(filter: { relativePath: { glob: "content/about/*.jpg" } }) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 470, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    }
`;
