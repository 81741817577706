import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { fluidImageType } from '../helper/prop-types';
import { mq } from '../helper/stylehelper';

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 50px;
    &:last-child {
        padding-bottom: 0;
    }
    ${mq.medium`
        flex-direction: row;
    `};

    ${mq.large`
        flex-direction: row;
    `};
`;
const TextWrapper = styled.div`
    display: flex;
    flex: 1;
    padding-top: 15px;

    ${mq.medium`
        max-width: 100%;
        padding: ${props => (props.rtl ? '0 20px 0 0' : '0 0 0 20px')};
    `};

    ${mq.large`
        max-width: 60%;
        padding: ${props => (props.rtl ? '0 30px 0 0' : '0 0 0 30px')};
    `};
`;
const ImageWrapper = styled(Img)`
    flex: 1;
    order: -1;
    height: 100%;
    ${mq.medium`
        max-width: 100%;
        order: ${props => (props.rtl ? '1' : '-1')};
    `};

    ${mq.large`

        max-width: 40%;
        order: ${props => (props.rtl ? '1' : '-1')};
    `};
`;

const ImageText = ({ image, children, alt, rtl }) => (
    <Wrapper>
        <ImageWrapper fluid={image} alt={alt} rtl={rtl} />
        <TextWrapper rtl={rtl}>{children}</TextWrapper>
    </Wrapper>
);

ImageText.defaultProps = {
    rtl: false,
};
export default ImageText;
ImageText.propTypes = {
    image: fluidImageType.isRequired,
    alt: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    rtl: PropTypes.bool,
};
